import React, { useContext } from "react";
import { Box, Typography, Grid, Button, Container } from "@mui/material";
import { LoginHeader } from "../../components/Header";
import PricingBanner from "../../assets/images/PricingBanner.png";
import Herosection from "../../components/HeroSection/HeroSection";
import PlanCard from "../../components/PlanCard/planCard";
import { getPlans } from "../../apollo/server";
import { gql, useQuery } from "@apollo/client";
import Footer from "../../components/HomeScreen/Footer";
import CommissionSection from "../../components/ComissionSection/ComissionSection";
import useStyle from "./styles";
import ReusableFAQs from "../../components/ReUsableFAQs/ReUsableFAQs";
import ReusableStepper from "../../components/ReusableStepper/ReusableStepper";
import ap from "../../../src/assets/images/ap.png";
import rectangle from "../../../src/assets/images/rectangle.png";
import fineprint from "../../../src/assets/images/fneprint.png";
import UserContext from "../../context/User";
import Header from "../../components/Header/Header";
import LeftCard from "../../components/LeftCard/LeftCard";

const GETPLANS = gql`
  ${getPlans}
`;

function PricingPage() {
  const { data, loading, error } = useQuery(GETPLANS, {
    variables: { status: true, plan_order: true },
  });
  const { isLoggedIn } = useContext(UserContext);

  const classes = useStyle();
  const frequentlyAskQuestions = [
    {
      title: "How do marketplace commission fees work?",
      description: `<p> When you make a sale on the Hedge marketplace, you'll encounter a straightforward commission fee structure. </p><br/>
<p>Either a fixed fee per item sold or a percentage of the total purchase amount per transaction.</p><br/>
<p>DIY Plan Example: A customer places an order for 10m2 of turf</p></br>
<p><Strong>Marketplace Fee:</Strong> $0.99c per item</p>
<Strong>Total Fee Payable:</Strong> 10 x 0.99c = $9.90 + sales tax`,
    },
    {
      title: "How do delivery commission fees work?",
      description: ` <p>In addition to marketplace commission fees Hedge applies either a percentage or fixed fee per delivery. </p><br/>
<p>DIY Plan Example: Delivery by your driver </p><br/>
<p><Strong>Your Local Delivery Fee:</Strong> $38.00</p>
<p><Strong>Hedge delivery commission:</Strong> 15%</p>
<p><Strong>Total Fee Payable to Hedge:</Strong> $5.70 + tax</p><br/>
*Please note: Even if you offer FREE DELIVERY, Hedge will still apply a $5.50 minimum 
commission fee for delivery. It's essential to bear this in mind when implementing 
free delivery to your customers. store.`,
    },
    {
      title: "Which subscription plan is right for me?",
      description: ` 

    <p>
        <Strong>DIY – Start Free, Grow at Your Pace</Strong>
    </p>
    <ul style="padding: 0 40px;">
        <li>
            <p >
                Who It's For: Businesses needing a streamlined setup and management.
            </p>
        </li>
        <li>
            <p>
               Key Benefits:
            </p>
            <ul style="padding: 0 40px;">
                <li>
                    <p>
                      Only Pay When You Sell: $0.99 marketplace commission per item sold, no upfront costs.
                    </p>
                </li>
                <li>
                    <p>
                        Live Delivery Tracking: Access Hedge’s driver app, set custom delivery fees, and manage zones for optimal control.
                    </p>
                </li>
                <li>
                    <p>
                        Zero Monthly Fees: Free to join; keep full control over your pricing and delivery.
                    </p>
                </li>
            </ul>
        </li>
    </ul>
    <br />
    <p>
        <Strong>DIFM – Do-It-For-Me, It’s Simple</Strong>
    </p>
    <ul style="padding: 0 40px;">
        <li>
            <p>
                Who It's For: Businesses needing a streamlined setup and management.
            </p>
        </li>
        <li>
            <p>
               Key Benefits:
            </p>
            <ul style="padding: 0 40px;">
                <li>
                    <p>
                      End-to-End Store Management: Hedge builds your online store, including product uploads, delivery zone setup, and fee management.
                    </p>
                </li>
                <li>
                    <p>
                        Flat Delivery Fee: $5.50 per delivery commission, ideal for predictability and cost savings.
                    </p>
                </li>
                <li>
                    <p>
                        Monthly Pricing: $59.99 for fully managed access to Hedge’s marketplace and delivery tools.
                    </p>
                </li>
            </ul>
        </li>
    </ul>
    <br />
    <p>
        <Strong>Enterprise – Premium, Wholesale Solution</Strong>
    </p>
    <ul style="padding: 0 40px;">
        <li>
            <p>
                Who It's For: High-volume brands, growers, suppliers seeking a fully managed, hands-off approach. 
            </p>
        </li>
        <li>
            <p>
                Key Benefits:
            </p>
            <ul style="padding: 0 40px;">
                <li>
                    <p>
                        Wholesale Advantage: Hedge buys products wholesale, creating a high-volume, fully managed retail presence for your brand.
                    </p>
                </li>
                <li>
                    <p>
                        Third-Party Delivery Coordination: Your drivers or third-party drivers, with premium logistics and live tracking.
                    </p>
                </li>
                <li>
                    <p>
                        Monthly Pricing: $99.99 provides a seamless, branded experience on a fully managed 24/7 online store.
                    </p>
                </li>
            </ul>
        </li>
    </ul>
    <br />
    <p>
        <Strong> Marketplace – Clearance Outlet </Strong>
    </p>
    <ul style="padding: 0 40px;">
        <li>
            <p>
                Who It's For: Hardware and Landscaping Supplies Stores, Suppliers, Brands, Growers and Wholesalers. 
            </p>
        </li>
        <li>
            <p>
                Key Benefits:
            </p>
            <ul style="padding: 0 40px;">
                <li>
                    <p>
                        Stock: Clear overstocked products quickly and easily.
                    </p>
                </li>
                <li>
                    <p>
                        New products: Launch new products in minutes.
                    </p>
                </li>
                <li>
                    <p>
                        More Customers: It’s where customers are searching for the deals and special offers. 
                    </p>
                </li>
            </ul>
        </li>
    </ul>
    <br />
    <p>
        *Note: All plans come with a minimum 12-month contract term. Should you have a change of heart, just inform us, and we'll accommodate your needs accordingly.
    </p>
    <br />

`,
    },
    {
      title: "What’s shopfront?",
      description: ` Well, help you add a unique button to your websites homepage so customers can 
choose between pickup or delivery easily.`,
    },
    {
      title: "How does pick up work?",
      description: ` <p>As you become familiar with just how easy Hedge is to use, you’ll find some really 
great features that will ensure you’ll never miss an order again. </p><br/>
<p>Customers can easily choose to pick up their purchase instore during checkout, you’ll 
be able to manage these settings in your dashboard and set the time that you need 
to prepare the customer’s order for pickup.</p> `,
    },
  ];

  React.useEffect(() => {
    if (!loading && !error && data) {
      console.log("Plans Data:", data);
    }
  }, [loading, error, data]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
      <Box
        sx={{ position: "relative", overflow: "hidden", alignItems: "center" }}
      >
        <Herosection
          imageSrc={PricingBanner}
          headingText="Pricing that's simple"
          descriptionText="Built for businesses of all sizes"
        />
      </Box>
      {/* <Card
        sx={{
          position: "absolute",
          right: "10%",
          top: "25rem",
          transform: "translateY(-50%)",
          width: "430px",
          borderRadius: "8px",
          zIndex: 10,
          display: {
            xs: "none", // Hide on extra-small screens
            sm: "none", // Hide on small screens
            md: "block", // Show on medium and larger screens
          },
        }}
      >
        <CardContent>
          <Typography
            variant="body2"
            sx={{
              color: "#808080",
              fontSize: "0.775rem",
              lineHeight: "2.5em",
              "& p": {
                marginBottom: "0.3em",
              },
            }}
          >
            <p>Take control of your online business.</p>
            <p>
              Select your plan and start selling the same day, equipped with
              your own branded online store and a delivery solution that
              customers will love.
            </p>
            <p>
              With features like 24/7 ordering and real-time delivery tracking
              you'll never miss an order again.
            </p>
          </Typography>
        </CardContent>
      </Card> */}
      <Container>
        <Box sx={{ textAlign: "center", marginTop: "70px" }}>
          <Typography
            variant="h5"
            sx={{ color: "black", fontWeight: "bold", marginBottom: "10px",fontFamily: "Be Vietnam Pro !important", }}
          >
            Start using Hedge for free
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "grey", fontSize: "1rem", marginBottom: "40px",fontFamily: "Be Vietnam Pro !important", }}
          >
            Reach more customers, in more neighborhoods with delivery or pickup
            today.
          </Typography>
        </Box>
        <Box
          sx={{
            padding: "30px 10px",
            marginBottom: "50px",
            display: "flex",
            justifyContent: "center",
            background: "#fff",
            boxShadow: "0px 4px 8px 0px #00000033",
            position: "relative",
          }}
        >
          {/* <Box sx={{position:"absolute",top:"0"}}>
            <Button
              sx={{
                // position: "absolute",
                // top: "-50px",
                // left: "50%",
                // transform: "translateX(-50%)",
                backgroundColor: "#c0e341",
                color: "black",
                padding: "9px 5px",
                borderRadius: "5px",
                fontWeight: "regular",
                fontSize: "16px",
                width: "201px",
                textAlign: "center",
              }}
            >
              Recommended Plan
            </Button>
          </Box> */}
          <Grid container spacing={3.5} sx={{paddingTop:'30px'}}>
            {data?.getPlans?.length > 0 ? (
              <>
                <Grid item xs={12} md={3}>
                  <Box
                    sx={{ padding: "0 20px", borderRight: "1px solid #B1B1B1" }}
                  >
                    <LeftCard />
                  </Box>
                </Grid>
                <Grid item sx={{ padding: "0 !important" }}>
                  <Box sx={{ padding: "0" }}>
                    <Grid
                      container
                      spacing={1}
                      className={classes.parentContainer}
                      mt={3}
                      sx={{
                        maxWidth: "874px",
                        overflowX: "auto",
                        flexWrap: "inherit",
                      }}
                    >
                      {data?.getPlans.map((plan, index) => (
                        <Grid item key={plan._id}>
                          <PlanCard
                            data={plan}
                            index={index}
                            total={data.length}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    padding: "0 20px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ color: "grey", fontSize: "1rem" }}
                  >
                    {`No Plan Available.`}
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
      <ReusableStepper
        sectionTitle="Included in your plan"
        steps={[
          {
            title: "Online Store",
            description:
              "Create your free online store on the Hedge marketplace today and expand your reach to a broader customer base. With the Hedge mobile app, customers can effortlessly browse through your products and place orders with ease. Connect your trade customers to a seamless ordering and delivery experience with the ability to add their online purchases to their instore trade account.",
          },
          {
            title: "Delivery/Pick up",
            description:
              "With Hedge, you have the flexibility to offer both delivery and pick-up for your customers. Our platform includes cutting-edge features such as real-time driver tracking, optimised delivery routes, contactless delivery. Simplify your logistics and enhance the customer experience with Hedge.",
          },
          {
            title: "Marketing Tools",
            description:
              "Boost your revenue potential with Hedge's advanced loyalty and advertising features. Seamlessly connect all your social media accounts and unlock a host of additional features to enhance your online presence and engage with your customers like never before.",
          },
          {
            title: "Business App",
            description:
              "Introducing the Hedge Business Manager App - your on-the-go solution for managing your business effortlessly. With just a tap, you can track and oversee orders, manage deliveries, and monitor your business seamlessly. Stay in control and keep your business running smoothly no matter where you are.",
          },
        ]}
      />
      <Box className={classes.startSimple}>
        <Container>
          <Box className={classes.startSimpleTitle}>
            <Typography variant="h2">The fine print</Typography>
          </Box>
          <Grid container spacing={12}>
            <Grid item sm={12} md={6} lg={6} className={classes.stepBox}>
              <Box className={classes.stepContainer}>
                <Box className={classes.stepperBox}>
                  <Box className={classes.stepperImage}>
                    <img src={rectangle} alt="rectangle" />
                  </Box>
                  <Box className={classes.stepperText}>
                    <Typography variant="h3">Commission</Typography>
                    <Typography>
                      Partners pay Hedge a fee based on all orders transacted
                      through the Hedge marketplace platform.
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.stepperBox}>
                  <Box className={classes.stepperImage}>
                    <img src={rectangle} alt="rectangle" />
                  </Box>
                  <Box className={classes.stepperText}>
                    <Typography variant="h3">Delivery</Typography>
                    <Typography>
                      Partners pay Hedge a fee based on deliveries processed
                      through the Hedge delivery app. Any tips left by the
                      customer will 100% be passed to the Driver.
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.stepperBox}>
                  <Box className={classes.hedgeLogo}>
                    <img src={ap} alt="ap" className={classes.hLogo} />
                  </Box>
                  <Box className={classes.stepperText}>
                    <Typography variant="h3">Payment</Typography>
                    <Typography>
                      For orders processed via the Hedge marketplace platform.
                      Hedge will remit payment to your nominated bank account
                      every 7 days, less any commissions, delivery or
                      subscription fees applicable.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={12} md={6} lg={6}>
              <Box>
                <img
                  src={fineprint}
                  alt="fineprintImage"
                  className={classes.sellingImage}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box marginTop={"50px"} marginBottom={"50px"}>
        <CommissionSection />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "80px",
        }}
      >
        <a
          href="https://www.hedge.net.au/become-a-stockist"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button className={classes.ChoosePlan}>Become A Stockist</Button>
        </a>
      </Box>
      <ReusableFAQs frequentlyAskQuestions={frequentlyAskQuestions} />
      <Footer />
    </>
  );
}

export default PricingPage;
