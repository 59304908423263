import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import LeftCard from "../LeftCard/LeftCard";

import useStyles from "./styles";

const PlanBoxs = ({ data, type }) => {
  const setStartSign = (info) => {
    const startSign =
      ["fixed", "per_item"].includes(info?.price_type) && info?.price > 1
        ? "$"
        : "";
    return startSign;
  };

  const isShowPrice = (price) => {
    return price > 0 ? price : "";
  };

  const setEndSign = (data) => {
    if (data?.price_type === "percentage") {
      return "%";
    } else if (data?.price_type === "cent") {
      return "c/cc";
    } else {
      return "";
    }
  };

  return (
    <>
      <Box
        sx={{
          borderBottom: "1px solid lightgray",
          // minHeight: type === "marketplace" ? "365px" : type === "delivery" ? "288px" : type === "shopfront" ? "245px" : "310px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          height: "81px",
          paddingBottom: "12px",
          marginBottom: "12px",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            // marginBottom: "10px",
            fontWeight: "bold",
            textAlign: "center",
            // alignItems: "center",
            color: "#2C2C2C !important",
            fontSize: "20px",
            fontFamily: "Be Vietnam Pro !important",
          }}
        >
          {`${setStartSign(data)}${isShowPrice(data?.price)}${setEndSign(
            data
          )}`}
        </Typography>
        <Typography
          variant="body1"
          sx={
            isShowPrice(data?.price)
              ? {
                  textAlign: "center",
                  fontFamily: "Be Vietnam Pro !important",
                  fontSize: "14px",
                  color: " #757575",
                  fontWeight: "300",
                  display: "-webkit-box",
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }
              : {
                  marginBottom: "10px",
                  fontWeight: "bold",
                  textAlign: "center",
                  alignItems: "center",
                  color: "inherit",
                  fontFamily: "Be Vietnam Pro !important",
                }
          }
        >
          {data?.description}
        </Typography>
      </Box>
    </>
  );
};

const PlanCard = ({ data, index, total }) => {
  console.log("data pay ==> ", data);
  const isDIFM = data.name === "DIFM"; // Check if the plan is DIFM
  console.log("difname", isDIFM);
  const navigate = useNavigate();
  const classes = useStyles();

  const handlePlanSubmission = () => {
    navigate("/ListingBusiness", {
      state: { planName: data.name, planData: data, planPrice: data.price },
    });
  };

  return (
    <Box className={classes.parentContainer}>
      {/* {index === 0 && (
        <Box sx={{ width: "550px" }} xs={8} md={4} className={classes.infoContainer}>
          <LeftCard />
        </Box>
      )} */}
      <Box
        sx={{
          // maxWidth: "100%",
          minWidth:'280px',
          width: `100%`, // Adjust the width to be narrow
          padding: "20px",
          paddingBottom: "20px",
          border: `${isDIFM ? "2px solid #c0e341" : "none"}`, // Border color for DIFM
          borderRadius: "8px",
          marginBottom: "20px",
          // position: "relative", // To position the label absolutely,
          // boxShadow: "0px 4px 8px 0px #00000033",
        }}
      >
        {isDIFM && (
          <Button
            sx={{
              position: "absolute",
              top: "0",
              margin: "0px 45px",
              backgroundColor: "#CED111",
              color: "black",
              padding: "9px 5px",
              borderRadius: " 5px",
              left: " 50%",
              transform: "translateX(-20px)",
              fontWeight: "regular",
              fontSize: "16px",
              width: "201px",
              textAlign: "center",
              fontFamily: "Be Vietnam Pro !important",
              border:'1px soldi transparnt',
              "&:hover": {
                border:"1px solid #CED111",
                color:'#CED111',
              },
              textTransform: 'none', 
            }}
          >
            Recommended Plan
          </Button>
        )}
        <Box
          sx={{
            height: "101px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#CED111",
              fontWeight: "600",
              textAlign: "center",
              fontSize: "22px",
            }}
          >
            {data.name}
          </Typography>

          <Typography
            variant="body1"
            sx={{ color: "#757575", 
              textAlign: "center", 
              fontSize: "14px",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              overflow: "hidden", }}
          >
            {data?.description}
          </Typography>
        </Box>

        {/* for payasyougo */}
        <Box
          sx={{
            borderBottom: "1px solid lightgrey",
            borderTop: "1px solid lightgrey",
            pb: 1.5,
            mb: 1.5,
            height: "73px",
            display: "flex",
            alignItems: "center",
            padding: "0",
            textAlign: "center",
            width: "100%",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "#2C2C2C",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "600",
              lineHeight: "30.26px",
              fontFamily: "Be Vietnam Pro ,sans-serif !important",
              width: "100%",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
          >
            {data?.payas}
          </Typography>
        </Box>

        {/* for overview */}
        <Box
          sx={{
            borderBottom: "1px solid lightgrey",
            pb: 1.5,
            mb: 1.5,
            height: "80px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "#757575",
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "300",
              lineHeight: "20.24px",
              fontFamily: "Be Vietnam Pro ,sans-serif",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              height: "60px",
            }}
          >
            {data?.overview}
          </Typography>
        </Box>

        {/* for marketplace */}
        <PlanBoxs data={data?.marketplace} type={"marketplace"} />

        {/* for delivery */}
        <PlanBoxs data={data?.delivery} type={"delivery"} />

        {/* for shopfront */}
        {/* <PlanBoxs data={data?.shopfront} type={"shopfront"} /> */}

        {/* for subscription */}
        <PlanBoxs data={data?.subscription} type={"subscription"} />

        {/* Divider above the button */}
        <Box sx={{ margin: "20px 0" }} />

        {/* Button */}
        <Box sx={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{
              width: "100%",
              maxWidth: "190px",
              backgroundColor: isDIFM ? "#000" : "#fff", // Button color for DIFM
              color: isDIFM ? "#fffff" : "#0D0D0D",
              "&:hover": {
                backgroundColor: isDIFM ? "#333" : "#CED111", // Button hover color for DIFM
              },
              textTransform: "none",
              ...(index === 2 && { px: "6px" }),
              height: "50px",
              border: "1px solid #aca9a9",
              boxShadow: "none",
              borderRadius: " 4px",
              fontSize: "14px",
              // color: "#0D0D0D",
              fontFamily: "Be Vietnam Pro !important",
              fontWeight: "600",
            }}
            onClick={handlePlanSubmission}
          >
            Sign up for {data.name}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PlanCard;
