import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    padding: "10px",
    paddingTop: "48px",


    [theme.breakpoints.down("sm")]: {
      // paddingBottom: "80px",
      paddingLeft: "100px",
      paddingRight: "100px"
    },
  },
}));

export default useStyles;
